import { isUpcycling } from './utils'

const upcyclingTema = {
  primaryColor: '#61ce70',
  secondaryColor: '#93278f',
  textColor: '#fff',
  borderColor: '#93278f'
}

const saveAddTema = {
  primaryColor: '#00a99d',
  secondaryColor: '#00a3a9',
  textColor: '#fff',
  borderColor: '#00a3a9'
}

export const getTema = () => {
  return isUpcycling() ? upcyclingTema : saveAddTema
}
