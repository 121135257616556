import Progress from 'components/profile/progress'
import BotaoTrilhas from 'components/profile/trilha'
import { formStatus } from 'components/profile/trilha/status'
import { filtrarFormulariosPorStatus, trilhas } from 'components/profile/trilha/trilhas'
import { Screen } from 'components/style'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

import 'components/profile/trilha/style.css'

const Perfil = (props) => {
  const getCurrentTrilhas = () => {
    if (props.perfil) {
      const { doacao, venda } = props.perfil

      if (doacao?.doador || doacao?.donatario) {
        return filtrarFormulariosPorStatus(trilhas.doacao.forms, formStatus.CAN_ANSWER)
      } else if (venda?.vendedor || venda?.comprador) {
        return filtrarFormulariosPorStatus(trilhas.vendaAlimentos.forms, formStatus.CAN_ANSWER)
      }
    }
    return []
  }

  const currentTrilhas = getCurrentTrilhas()
  const totalForms = currentTrilhas.length
  const completedForms = filtrarFormulariosPorStatus(currentTrilhas, formStatus.COMPLETE).length

  const renderTrilha = (form) => {
    return (
      <Col xs={6} sm={4} md={3} lg={2} xl={2} key={form.idform}>
        <BotaoTrilhas idform={form.idform} status={form.status} url={`/perfil/trilhas/formulario/${form.idform}`} />
        <div className='text-center mb-0'>{form.name}</div>
      </Col>
    )
  }

  return (
    <Screen back={{ to: '/', title: 'Menu' }}>
      <Container>
        <div className='card-body py-2'>
          <h5 className='card-title'>Progresso geral</h5>
          <div className='mb-2 mt-4'>
            <Progress now={completedForms} total={totalForms} />
          </div>
        </div>
        <div className='card-body py-2 trilha-container'>
          <h5 className='card-title mt-4'>Trilhas</h5>
          <Row>{currentTrilhas.map(renderTrilha)}</Row>
        </div>
      </Container>
    </Screen>
  )
}

const mapStateToProps = (state) => ({
  perfil: state.perfil
})

export default connect(mapStateToProps)(Perfil)
