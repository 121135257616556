import { Card } from 'react-bootstrap'
import { getTema } from './tema'

function CardTrilha ({ trilha, imagem, onClick }) {
  const { borderColor, primaryColor, textColor } = getTema()
  const imageContainerStyle = {
    height: '120px'
  }

  return (
    <Card
      onClick={onClick}
      className='card-trilha h-100'
      style={{
        cursor: 'pointer',
        borderRadius: '12px',
        border: `3px solid ${borderColor}`,
        backgroundColor: primaryColor
      }}
    >
      <div style={imageContainerStyle} className='d-flex align-items-center justify-content-center'>
        {imagem ? <img src={imagem} alt={trilha} className='rounded-top' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> : <p>Imagem</p>}
      </div>
      <Card.Body className='d-flex flex-column justify-content-center' style={{ color: textColor, padding: '0.5rem', backgroundColor: primaryColor }}>
        <Card.Title className='text-center fw-bold fs-5'>{trilha}</Card.Title>
      </Card.Body>
    </Card>
  )
}

export default CardTrilha
