export const TipoHorarioEnum = {
  DIARIO: 1,
  SEMANAL: 2
}

export const DaysOfWeekEnum = {
  Domingo: { name: 'Domingo', abreviation: 'Dom', value: 0 },
  Segunda: { name: 'Segunda', abreviation: 'Seg', value: 1 },
  Terca: { name: 'Terça', abreviation: 'Ter', value: 2 },
  Quarta: { name: 'Quarta', abreviation: 'Qua', value: 3 },
  Quinta: { name: 'Quinta', abreviation: 'Qui', value: 4 },
  Sexta: { name: 'Sexta', abreviation: 'Sex', value: 5 },
  Sabado: { name: 'Sábado', abreviation: 'Sab', value: 6 }
}

export const DayOfWeekArray = [
  DaysOfWeekEnum.Domingo,
  DaysOfWeekEnum.Segunda,
  DaysOfWeekEnum.Terca,
  DaysOfWeekEnum.Quarta,
  DaysOfWeekEnum.Quinta,
  DaysOfWeekEnum.Sexta,
  DaysOfWeekEnum.Sabado
]

export const DisplayDaysOfWeek = (day) => {
  const items = DayOfWeekArray.filter((x) => x.value === day)
  return items ? items[0].name : ''
}

export const opcoesUnidadeProduto = () => ['CX', 'KG', 'UN', 'SACO', 'PCT']

export const opcoesUnidadeProdutoSelect = () => {
  try {
    return opcoesUnidadeProduto().map((item) => ({ value: item, label: item }))
  } catch (error) {
    console.error('Ocorreu um erro ao criar as opções de unidade de produto:', error)
    return []
  }
}
