import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alignment from 'components/Alignment'
import InputNumber from 'components/input/inputNumberConfirm'
import EstoqueSwitchComPreco from 'components/produto/estoqueSwitchComPreco'
import dayjs from 'dayjs'
import Proptypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { opcoesUnidadeProdutoSelect } from 'util/enums'
import { orderBy, produtosToSelect } from 'util/importar-produtos-lote'
import { getNumberPositiveOrZero, isArrayEmpty, isArrayNotEmpty } from 'util/utils'

const ExibirProduto = ({ produtoBase, preco, unidade, onChange, onRemove, isPreco, isEstoque, isSale }) => {
  const [unidadeBase, setUnidadeBase] = useState(unidade)
  const [precoBase, setPrecoBase] = useState(preco)
  const { nomeProduto, idProduto } = produtoBase
  const unidadesOptions = opcoesUnidadeProdutoSelect()
  const valor = isPreco ? produtoBase.precoBase : precoBase

  const validade = produtoBase.vencimento ? produtoBase.vencimento : undefined

  const daysUntilExpire = validade ? dayjs(validade).diff(dayjs(), 'days') : undefined

  const handleChangeUnidade = (e) => {
    onChange({
      produto: produtoBase,
      unidade: e.value,
      preco: precoBase
    })
    setUnidadeBase(e.value)
  }

  const handleChangePreco = (e) => {
    if (isPreco) return
    const novoPreco = e && e.target ? e.target.value : getNumberPositiveOrZero(e)
    if (novoPreco === valor) return

    onChange({
      produto: produtoBase,
      preco: novoPreco,
      unidade: unidadeBase
    })
    setPrecoBase(novoPreco)
  }

  return (
    <tr className=' product-line-table table-opened'>
      <td colSpan='12'>
        <Alignment vMiddle>
          <span name='produtoContrato' className='float-left'>
            <strong className='d-inline-block'>{nomeProduto}</strong>
            <small className='compressed-value'>
              {(isSale && preco, ' ')}
              {isEstoque ? produtoBase.unidadeBase : unidadeBase} )
            </small>
          </span>
          <Button variant='light' style={{ marginLeft: 'auto' }} onClick={() => onRemove(idProduto)}>
            <FontAwesomeIcon icon={faTimes} color='#FF0000' size='sm' />
          </Button>
        </Alignment>
        {validade && (
          <div>
            <div>
              <b>Vencimento: </b>
              {dayjs(validade).format('DD/MM/YYYY')}
            </div>
            <div>
              <b>Dias para vencer: </b>
              {daysUntilExpire > 0 ? daysUntilExpire : 'Vencido'}
            </div>
          </div>
        )}
      </td>
      <td className={"inside-table table-closed'}"} key={`tb_produto_item_${idProduto}`}>
        <Row>
          <Col>
            Unidade
            <Select
              isSearchable={false}
              id={`unidade_${idProduto}`}
              key={`unidade_${idProduto}`}
              placeholder='Unidade'
              options={unidadesOptions}
              onChange={handleChangeUnidade}
              value={{ value: unidadeBase, label: unidadeBase }}
              isDisabled={isEstoque}
              className='styled-select-unidade'
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </Col>
          {isSale && (
            <Col>
              Preço
              <InputNumber
                type='number'
                id={`preco_${idProduto}`}
                className='form-control py-1 w-100 nospin'
                style={{ minHeight: '38px' }}
                valor={valor}
                min={0}
                mobile
                precision={2}
                onChange={handleChangePreco}
                disabled={isPreco}
              />
            </Col>
          )}
        </Row>
        <Row>&nbsp;</Row>
      </td>
    </tr>
  )
}

ExibirProduto.propTypes = {
  produtoBase: Proptypes.object.isRequired,
  onRemove: Proptypes.func.isRequired,
  onChange: Proptypes.func.isRequired,
  removido: Proptypes.bool,
  isPreco: Proptypes.bool.isRequired,
  isEstoque: Proptypes.bool.isRequired
}

function ListarProdutos ({ cadastrados, selecionados, onAdd, onRemove, onChange, isEstoque, isPreco, onChangeEstoque, onChangePreco, isSale }) {
  const [cadastradosOrdenados, setCadastradosOrdenados] = useState([])
  const [selecionadosOrdenados, setSelecionadosOrdenados] = useState([])
  useEffect(() => {
    if (isArrayNotEmpty(selecionados)) {
      setSelecionadosOrdenados(orderBy(selecionados))
    } else {
      setSelecionadosOrdenados([])
    }
    if (isArrayNotEmpty(cadastrados)) {
      setCadastradosOrdenados(produtosToSelect(orderBy(cadastrados.filter((produto) => !selecionados.some((s) => s.idProduto === produto.idProduto)))))
    } else {
      setCadastradosOrdenados([])
    }
  }, [selecionados, cadastrados])

  if (isArrayNotEmpty(cadastrados)) {
    return (
      <div>
        <EstoqueSwitchComPreco isEstoque={isEstoque} onChangeEstoque={onChangeEstoque} isPreco={isPreco} onChangePreco={onChangePreco} isSale={isSale} />
        <Select
          id='select-produtos'
          value='0'
          placeholder={isArrayNotEmpty(cadastradosOrdenados) ? 'Selecione o produto...' : 'Não há produtos para adicionar'}
          options={cadastradosOrdenados}
          isDisabled={isArrayEmpty(cadastradosOrdenados)}
          cacheOptions
          defaultOptions
          searchable
          onChange={(item) => onAdd(item.value)}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 })
          }}
        />
        {isArrayEmpty(selecionadosOrdenados) ? (
          <p>
            <br />
            Não há produtos selecionados para listar
          </p>
        ) : (
          <table border='0' className='mt-4 mb-4 table table-sm table-borderless table-striped product-table'>
            <tbody>
              {selecionadosOrdenados.map(({ produto, unidade, preco }) => (
                <ExibirProduto
                  key={produto.idProduto}
                  unidade={unidade}
                  preco={preco}
                  produtoBase={produto}
                  onRemove={onRemove}
                  onChange={onChange}
                  isPreco={isPreco}
                  isEstoque={isEstoque}
                  isSale={isSale}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return <>Não existem produtos disponiveis para cadastro</>
}

ListarProdutos.propTypes = {
  cadastrados: Proptypes.array.isRequired,
  selecionados: Proptypes.array,
  onAdd: Proptypes.func.isRequired,
  onRemove: Proptypes.func.isRequired,
  onChange: Proptypes.func.isRequired,
  isSale: Proptypes.bool,
  isEstoque: Proptypes.bool.isRequired,
  isPreco: function (props, propName, componentName) {
    if (props.isSale && typeof props[propName] !== 'boolean') {
      console.log(props)
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`)
    }
  },
  onChangeEstoque: Proptypes.func.isRequired,
  onChangePreco: function (props, propName, componentName) {
    if (props.isSale && !props[propName]) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`)
    }
  }
}

export default ListarProdutos
