import { Route, Switch } from 'react-router-dom'

import Login from 'pages/loginForm'

import DacoesRoutesArray from 'modules/negocios/doacao/routes'
import VendasRoutesArray from 'modules/negocios/venda/routes'

import TrilhaFormulario from 'pages/perfil/forms'
import Perfil from 'pages/perfil/index'
import VendaPerfilInteresses from 'pages/perfil/interesses'
import SelecaoPerfil from 'pages/perfil/selecaoPerfil'
import SelecaoTrilhas from 'pages/perfil/selecaoTrilhas'
import TrilhaStatus from 'pages/perfil/status'
import VendaPerfilVisualizar from 'pages/perfil/visualizar'

import ImportarProdutosEmLotes from 'pages/importar-produtos-lote'

import Produto from 'pages/produto'

import editarProdutoComprador from 'pages/produto/edit-comprador'
import editarProdutoCompradorLote from 'pages/produto/edit-comprador-lote'

import EditarRastreioDonatario from 'pages/rastreio/edit-donatario'

import Logout from 'pages/logout'
import EditaCatalogo from 'pages/produto/catalogo'
import ListProdutos from 'pages/produto/list'

import UsuarioNovo from 'pages/admin/cadastro-usuario/novo'

import editarProduto from 'pages/produto/edit'

export default () => {
  const routesDoacoes = DacoesRoutesArray.map(({ path, component }, key) => <Route exact path={path} component={component} key={`doacoes_route_${key}`} />)
  const routesVendas = VendasRoutesArray.map(({ path, component }, key) => <Route exact path={path} component={component} key={`vendas_route_${key}`} />)

  return (
    <Switch>
      <Route path='/login' component={Login} />

      <Route path='/produtos/importar' component={ImportarProdutosEmLotes} />

      {routesDoacoes}
      <Route path='/produtos/edit-doacao/:id' component={EditarRastreioDonatario} />

      {routesVendas}
      <Route path='/perfil/trilhas/formulario/:id' component={TrilhaFormulario} />
      <Route path='/perfil/trilhas/:status' component={TrilhaStatus} />
      <Route path='/perfil' component={Perfil} exact />
      <Route path='/perfil/trilhas' component={SelecaoTrilhas} />
      <Route path='/perfil/visualizar' component={VendaPerfilVisualizar} />

      <Route path='/perfil/interesses' component={VendaPerfilInteresses} />
      <Route path='/perfil/selecionar' component={SelecaoPerfil} />

      <Route path='/produto/:id' component={Produto} />
      <Route exact path='/produtos' component={ListProdutos} />
      <Route exact path='/produtos/edit-comprador/:id' component={editarProdutoComprador} />
      <Route exact path='/produtos/edit-comprador' component={editarProdutoCompradorLote} />
      <Route exact path='/produtos/catalogo' component={EditaCatalogo} />
      <Route exact path='/produtos/edit/:id' component={editarProduto} />

      <Route exact path='/admin/usuarios/novo' component={UsuarioNovo} />

      <Route path='/logout' component={Logout} />
      <Route path='/' component={Login} />

      <Route component={() => <p>Not found</p>} />
    </Switch>
  )
}
