import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Proptypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'

import Alignment from 'components/Alignment'

import EstoqueSwitch from 'components/produto/estoqueSwitch'
import { opcoesUnidadeProdutoSelect } from 'util/enums'
import { orderBy, produtosToSelect } from 'util/importar-produtos-lote'

import { isArrayEmpty, isArrayNotEmpty } from 'util/utils'

const ExibirProdutoAberto = ({ produto, onChange }) => {
  const unidadesOptions = opcoesUnidadeProdutoSelect()
  const { idProduto, unidade } = produto

  const handleChangeUnidade = (e) => {
    onChange({ ...produto, unidade: e.value })
  }

  return (
    <td className={`inside-table table-opened`} key={`tb_produto_item_${idProduto}`}>
      <Select
        isSearchable={false}
        id={`unidade_${idProduto}`}
        S
        key={`unidade_${idProduto}`}
        name='unidade'
        placeholder='Unidade'
        options={unidadesOptions}
        onChange={handleChangeUnidade}
        value={{ value: unidade || 'KG', label: unidade || 'KG' }}
        className='styled-select-unidade'
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 })
        }}
      />
    </td>
  )
}

ExibirProdutoAberto.propTypes = {
  produto: Proptypes.object.isRequired,
  onChange: Proptypes.func.isRequired
}

const ExibirProduto = ({ produto, onChange, onRemove }) => {
  const { nomeProduto, unidade, idProduto } = produto

  return (
    <tr className={`product-line-table table-opened`}>
      {' '}
      <td>
        <Alignment vMiddle>
          <span className='float-left'>
            {' '}
            <strong className='d-inline-block'>{nomeProduto}</strong>
            <small className='compressed-value'>{`${unidade || 'KG'})`}</small>
          </span>
          <Button variant='light' style={{ marginLeft: 'auto' }} onClick={() => onRemove(idProduto)}>
            <FontAwesomeIcon icon={faTimes} color='#FF0000' size='sm' />
          </Button>
        </Alignment>
      </td>
      <ExibirProdutoAberto produto={produto} onChange={onChange} />
    </tr>
  )
}

ExibirProduto.propTypes = {
  produto: Proptypes.object.isRequired,
  onRemove: Proptypes.func.isRequired,
  onChange: Proptypes.func.isRequired
}

const ExibirProdutos = ({ produtos, onRemove, onChange }) => {
  if (isArrayNotEmpty(produtos)) {
    return (
      <table border='0' className='mt-4 mb-4 table table-sm table-borderless table-striped product-table'>
        <tbody>
          {produtos.map((p) => (
            <ExibirProduto key={p.idProduto} produto={{ ...p, unidade: p.unidade || 'KG' }} onRemove={onRemove} onChange={onChange} />
          ))}
        </tbody>
      </table>
    )
  }
  return null
}

ExibirProdutos.propTypes = {
  produtos: Proptypes.array.isRequired,
  onRemove: Proptypes.func.isRequired,
  onChange: Proptypes.func.isRequired
}

const SelectProdutos = ({ produtos, onAdd }) => (
  <Select
    id='select-produtos'
    value='0'
    placeholder={isArrayNotEmpty(produtos) ? 'Selecione o produto...' : 'Não há produtos para adicionar'}
    options={produtos}
    isDisabled={isArrayEmpty(produtos)}
    cacheOptions
    defaultOptions
    searchable
    onChange={(item) => onAdd(item.value)}
    styles={{
      menu: (provided) => ({ ...provided, zIndex: 9999 })
    }}
  />
)

function ListarProdutos ({ cadastrados, selecionados, onAdd, onRemove, onChange, isEstoque, onChangeEstoque, hideSwitch }) {
  const [cadastradosOrdenados, setCadastradosOrdenados] = useState([])
  const [selecionadosOrdenados, setSelecionadosOrdenados] = useState([])

  useEffect(() => {
    if (isArrayNotEmpty(selecionados)) {
      setSelecionadosOrdenados(orderBy(selecionados))
    } else {
      setSelecionadosOrdenados([])
    }
    if (isArrayNotEmpty(cadastrados)) {
      setCadastradosOrdenados(produtosToSelect(orderBy(cadastrados.filter((produto) => !selecionados.some((s) => s.idProduto === produto.idProduto)))))
    } else {
      setCadastradosOrdenados([])
    }
  }, [selecionados, cadastrados])

  if (isArrayNotEmpty(cadastrados)) {
    return (
      <Row>
        {hideSwitch ? null : <EstoqueSwitch isEstoque={isEstoque} onChangeEstoque={onChangeEstoque} />}

        <Col>
          <SelectProdutos produtos={cadastradosOrdenados} onAdd={onAdd} />
          {isArrayEmpty(selecionadosOrdenados) ? (
            <p>
              <br />
              Não há produtos selecionados para listar
            </p>
          ) : null}
          <ExibirProdutos produtos={selecionadosOrdenados} onChange={onChange} onRemove={onRemove} />
        </Col>
      </Row>
    )
  }

  return <>Não existem produtos disponiveis para cadastro</>
}

ListarProdutos.propTypes = {
  cadastrados: Proptypes.array.isRequired,
  selecionados: Proptypes.array,
  onAdd: Proptypes.func.isRequired,
  onRemove: Proptypes.func.isRequired,
  onChange: Proptypes.func.isRequired
}

export default ListarProdutos
