import { trilhas } from 'components/profile/trilha/trilhas'
import { Screen } from 'components/style'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CardTrilha from 'util/cardsTrilhas'
import { isUpcycling } from 'util/utils'

function SelecaoTrilhas ({ perfil }) {
  const history = useHistory()
  const [trilhasExibidas, setTrilhasExibidas] = React.useState([])

  React.useEffect(() => {
    setTrilhasExibidas(determinarTrilhas(perfil))
  }, [perfil])

  const determinarTrilhas = (perfil) => {
    if (isUpcycling()) {
      return getTrilhasUpcycling()
    } else {
      return getTrilhasBaseadoNoPerfil(perfil)
    }
  }

  const getTrilhasUpcycling = () => {
    return [
      trilhas.internoAquisicaoResiduos,
      trilhas.internoValorizacaoResiduos,
      trilhas.internoParceiros,
      trilhas.externoAquisicaoResiduos,
      trilhas.externoValorizacaoResiduos,
      trilhas.externoParceiros
    ]
  }

  const getTrilhasBaseadoNoPerfil = (perfil) => {
    const trilhasDoPerfil = []

    if (perfil?.doacao?.doador || perfil?.doacao?.donatario) {
      trilhasDoPerfil.push(trilhas.doacao)
    }
    if (perfil?.venda?.vendedor || perfil?.venda?.comprador) {
      trilhasDoPerfil.push(trilhas.vendaAlimentos)
    }

    return trilhasDoPerfil
  }

  const handleCardClick = (trilha) => {
    if (isUpcycling()) {
      redirecionarParaFormularioUpcycling(trilha)
    } else {
      navegarParaPerfilComTrilha(trilha)
    }
  }

  const redirecionarParaFormularioUpcycling = (trilha) => {
    window.location.href = trilha.forms[0].url
  }

  const navegarParaPerfilComTrilha = (trilha) => {
    history.push('/perfil', { trilha })
  }

  const renderTrilhas = () => {
    return trilhasExibidas.map((trilha) => (
      <Col key={trilha.trilha} xs={12} md={!isUpcycling() ? 12 : 6} lg={!isUpcycling() ? 12 : 4} className='my-3'>
        <CardTrilha trilha={trilha.trilha} descricao={trilha.descricao} imagem={trilha.imagem} onClick={() => handleCardClick(trilha)} />
      </Col>
    ))
  }

  return (
    <Screen back={{ to: '/', title: 'Menu' }}>
      <Container className='px-3'>
        <div className='d-flex flex-column w-100' style={{ alignItems: 'start', textAlign: 'left' }}>
          <h2 className='mb-4'>Selecione uma trilha</h2>

          <Row>{trilhasExibidas.length > 0 ? renderTrilhas() : <p>Não há trilhas para exibir.</p>}</Row>
        </div>
      </Container>
    </Screen>
  )
}

const mapStateToProps = (state) => ({
  perfil: state.perfil
})

export default connect(mapStateToProps)(SelecaoTrilhas)
