import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faBoxOpen, faBullhorn, faFileSignature, faReceipt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MenuItem from 'components/MenuItem'
import { Screen } from 'components/style'

import { getTipoPerfil } from 'util/tipoPerfil'
import { isUpcycling } from 'util/utils'

import URL_DOACAO from 'modules/negocios/doacao/urls-browser'
import URL_VENDA from 'modules/negocios/venda/urls-browser'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography component='div' role='tabpanel' hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper
  }
}))

function ExibirMenuVendedor () {
  return (
    <Container>
      <div className='menu-wrapper row justify-content-center'>
        <MenuItem to={URL_VENDA.CONTRATO.LISTAR('todos')} menuicon={<FontAwesomeIcon icon={faFileSignature} size='lg' />} label='Relacionamentos' name='relacionamentos' />
        <MenuItem to={URL_VENDA.ANUNCIO.LISTAR()} menuicon={<FontAwesomeIcon icon={faBullhorn} size='lg' />} label='Anúncios' name='anuncios' />
        <MenuItem to={URL_VENDA.ENTREGA.ANUNCIANTE.LISTAR()} menuicon={<FontAwesomeIcon icon={faReceipt} size='lg' />} label='Entregas' name='entregas' />
        <MenuItem to='/produtos' menuicon={<FontAwesomeIcon icon={faBoxOpen} size='lg' />} label='Estoque' name='estoque' />
        <MenuItem to='/perfil/trilhas' menuicon={<FontAwesomeIcon icon={faUser} size='lg' />} label='Perfil' name='trilhas' />
      </div>
    </Container>
  )
}

function ExibirMenuComprador () {
  return (
    <Container>
      <div className='menu-wrapper row justify-content-center'>
        <MenuItem to={URL_VENDA.RESERVA.LISTAR()} menuicon={<FontAwesomeIcon icon={faBullhorn} size='lg' />} label='Reservar' />
        <MenuItem to={URL_VENDA.ENTREGA.COMPRADOR.LISTAR()} menuicon={<FontAwesomeIcon icon={faReceipt} size='lg' />} label='Entregas' />
        <MenuItem to='/perfil/trilhas' menuicon={<FontAwesomeIcon icon={faUser} size='lg' />} label='Perfil' />
        <MenuItem to='/produtos' menuicon={<FontAwesomeIcon icon={faBoxOpen} size='lg' />} label='Estoque' className='saveadd-secondary-color' />
      </div>
    </Container>
  )
}

function ExibirMenuDoador () {
  return (
    <Container>
      <div className='menu-wrapper row justify-content-center'>
        <MenuItem
          to={URL_DOACAO.CONTRATO.LISTAR('todos')}
          menuicon={<FontAwesomeIcon icon={faFileSignature} size='lg' />}
          label='Relacionamentos'
          className='saveadd-secondary-color'
        />
        <MenuItem to={URL_DOACAO.ANUNCIO.LISTAR()} menuicon={<FontAwesomeIcon icon={faBullhorn} size='lg' />} label='Anúncios' className='saveadd-secondary-color' />
        <MenuItem to={URL_DOACAO.ENTREGA.DOADOR.LISTAR()} menuicon={<FontAwesomeIcon icon={faReceipt} size='lg' />} label='Entregas' className='saveadd-secondary-color' />
        <MenuItem to='/produtos' menuicon={<FontAwesomeIcon icon={faBoxOpen} size='lg' />} label='Estoque' className='saveadd-secondary-color' />
        <MenuItem to='/perfil/trilhas' menuicon={<FontAwesomeIcon icon={faUser} size='lg' />} label='Perfil' className='saveadd-secondary-color' />
      </div>
    </Container>
  )
}

function ExibirMenuDonatario ({ perfil }) {
  return (
    <Container>
      <div className='menu-wrapper row justify-content-center'>
        <MenuItem to={URL_DOACAO.RESERVA.LISTAR(perfil)} menuicon={<FontAwesomeIcon icon={faBullhorn} size='lg' />} label='Reservar' className='saveadd-secondary-color' />

        <MenuItem to={URL_DOACAO.ENTREGA.DONATARIO.LISTAR()} menuicon={<FontAwesomeIcon icon={faReceipt} size='lg' />} label='Entregas' className='saveadd-secondary-color' />
        <MenuItem to='/perfil/trilhas' menuicon={<FontAwesomeIcon icon={faUser} size='lg' />} label='Perfil' className='saveadd-secondary-color' />
        <MenuItem to='/produtos' menuicon={<FontAwesomeIcon icon={faBoxOpen} size='lg' />} label='Estoque' className='saveadd-secondary-color' />
      </div>
    </Container>
  )
}

function ExibirWhatsapp () {
  const phone = isUpcycling() ? '5511972401644' : '5511955510701'
  const whatsLink = `https://wa.me/${phone}`

  return (
    <div>
      <a className='whatsapp' id='whatsapp' href={whatsLink} target='_blank' rel='noopener noreferrer'>
        <FontAwesomeIcon className='whatsappIcon' icon={faWhatsapp} size='lg' />
      </a>
    </div>
  )
}

function FullWidthTabs (props) {
  const classes = useStyles()
  const { perfil } = props

  const renderMenu = () => {
    const tipoPerfil = getTipoPerfil(perfil)
    switch (tipoPerfil) {
      case 'Vendedor':
        return <ExibirMenuVendedor />
      case 'Comprador':
        return <ExibirMenuComprador />
      case 'Doador':
        return <ExibirMenuDoador />
      case 'Donatário':
        return <ExibirMenuDonatario />
      default:
        return <div>Sem perfil</div>
    }
  }

  return (
    <Screen title={'Menu'}>
      <div className={`w-100 ${classes.root}`}>
        {renderMenu()}
        <ExibirWhatsapp />
      </div>
    </Screen>
  )
}

const mapStateToProps = (state) => ({
  tabSelectedMainMenu: state.main.tabSelectedMainMenu,
  perfil: state.perfil
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FullWidthTabs)
