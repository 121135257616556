import { formStatus } from './status'

const vendaAlimentos = [
  {
    idform: 'gONx6Bcn',
    url: 'https://saveaddsolutions.typeform.com/to/gONx6Bcn',
    name: 'Detalhes do interesse'
  },
  {
    idform: 'EYxrtgzb',
    url: 'https://saveaddsolutions.typeform.com/to/EYxrtgzb',
    name: 'Qualificação técnicas avançada'
  },
  {
    idform: 'wSVb6Bko',
    url: 'https://saveaddsolutions.typeform.com/to/wSVb6Bko',
    name: 'Capacidade de armazenamento avançado'
  },
  {
    idform: 'GfaiuUPf',
    url: 'https://saveaddsolutions.typeform.com/to/GfaiuUPf',
    name: 'Capacidade de retirada recorrente'
  },
  {
    idform: 'LM09Oyp2',
    url: 'https://saveaddsolutions.typeform.com/to/LM09Oyp2',
    name: 'Qualificação técnica básica'
  },
  {
    idform: 'mExfrj1o',
    url: 'https://saveaddsolutions.typeform.com/to/mExfrj1o',
    name: 'Capacidade de armazenamento básico'
  }
]

const doacao = [
  {
    idform: 'CS8jzd47',
    url: 'https://saveaddsolutions.typeform.com/to/CS8jzd47',
    name: 'Validação de perfil de ONG'
  },
  {
    idform: 'FC4Kr7jt',
    url: 'https://saveaddsolutions.typeform.com/to/FC4Kr7jt',
    name: 'Capacidade de retirada'
  },
  {
    idform: 'pBthZc',
    url: 'https://saveaddsolutions.typeform.com/to/pBthZc',
    name: 'Capacidade de armazenamento'
  },
  {
    idform: 'DGWbaZZl',
    url: 'https://saveaddsolutions.typeform.com/to/DGWbaZZl',
    name: 'Qualificação técnica de manuseio'
  }
]

//forms upcycling
const internoAquisicaoResiduos = [
  {
    idform: 'CS8jzd47',
    url: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__uDe5g1UNjJXWExEWEozQlJFVFZaMllWOFYzS1JPNi4u&route=shorturl',
    name: 'Interno: Aquisição de resíduos para produção'
  }
]

const internoValorizacaoResiduos = [
  {
    idform: 'CS8jzd48',
    url: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__uDe5g1UQ0NZRTAwUE5MWVc0ODhISTNHWlhNVFk5Ti4u&route=shorturl',
    name: 'Interno: Valorização de resíduos para produção'
  }
]
const internoParceiros = [
  {
    idform: 'CS8jzd49',
    url: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__uDe5g1UN0NIQUM3UE5MRDRKRzRVVE40QkNYVjZXQS4u&route=shorturl',
    name: 'Interno: Parceiros'
  }
]

const externoAquisicaoResiduos = [
  {
    idform: 'CS8jzd50',
    url: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__uDe5g1URVcyMEdGNkJUTUNSUTUxWThaWlVEVFA0Mi4u&route=shorturl',
    name: 'Externo: Aquisição de resíduos para produção'
  }
]

const externoValorizacaoResiduos = [
  {
    idform: 'CS8jzd51',
    url: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__uDe5g1URVlTRzgyNkpGUlYwWVBLSlBIRExKT1hPVy4u&route=shorturl',
    name: 'Externo: Valorização de resíduos para produção'
  }
]

const externoParceiros = [
  {
    idform: 'CS8jzd52',
    url: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__uDe5g1UQzk5RktPT0dJQlFWNU4yNFAyQjZTMDBTUy4u&route=shorturl',
    name: 'Externo: Parceiros'
  }
]

//incluir novas trilhas

const adicionarStatus = (forms) => forms.map((form) => ({ ...form, status: formStatus.CAN_ANSWER }))

export const filtrarFormulariosPorStatus = (forms, status) => forms.filter((form) => form.status === status)

export const trilhas = {
  doacao: {
    trilha: 'Doação',
    descricao: 'Trilha de doação para ONGs',
    imagem: '../images/perfil/cardDoacao.svg',
    forms: adicionarStatus(doacao)
  },
  vendaAlimentos: {
    trilha: 'Venda de Alimentos',
    descricao: 'Trilha de vendas para produtos',
    imagem: '../images/perfil/cardVenda.svg',
    forms: adicionarStatus(vendaAlimentos)
  },
  internoAquisicaoResiduos: {
    trilha: 'Interno: Aquisição de resíduos para produção',
    descricao: 'Trilha de aquisição de resíduos para produção',
    imagem: '../images/perfil/cardInternoAquisicao.svg',
    forms: adicionarStatus(internoAquisicaoResiduos)
  },
  internoValorizacaoResiduos: {
    trilha: 'Interno: Valorização de resíduos para produção',
    descricao: 'Trilha de valorização de resíduos para produção',
    imagem: '../images/perfil/cardInternoValorizacao.svg',
    forms: adicionarStatus(internoValorizacaoResiduos)
  },
  internoParceiros: {
    trilha: 'Interno: Parceiros',
    descricao: 'Trilha de parceiros',
    imagem: '../images/perfil/cardInternoParceiros.svg',
    forms: adicionarStatus(internoParceiros)
  },
  externoAquisicaoResiduos: {
    trilha: 'Externo: Aquisição de resíduos para produção',
    descricao: 'Trilha de aquisição de resíduos para produção',
    imagem: '../images/perfil/cardExternoAquisicao.svg',
    forms: adicionarStatus(externoAquisicaoResiduos)
  },
  externoValorizacaoResiduos: {
    trilha: 'Externo: Valorização de resíduos para produção',
    descricao: 'Trilha de valorização de resíduos para produção',
    imagem: '../images/perfil/cardExternoValorizacao.svg',
    forms: adicionarStatus(externoValorizacaoResiduos)
  },
  externoParceiros: {
    trilha: 'Externo: Parceiros',
    descricao: 'Trilha de parceiros',
    imagem: '../images/perfil/cardExternoParceiros.svg',
    forms: adicionarStatus(externoParceiros)
  }
  // incluir novas trilhas
}
